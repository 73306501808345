
import { createStore } from 'vuex'

export default createStore({
    state: {
    },
    getters: {
    },
    //同步修改state里面的值
    mutations: {
        set(state,payload){
        Object.keys(payload).forEach(e=>{
            if(Object.hasOwnProperty.call(state,e)){
                state[e] = payload[e];
            }else{
                console.error(`store未定义${e}属性`)
            }
        })
        }
    },
    actions: {
    },
    modules: {
    }
})