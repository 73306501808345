import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index',
    menuName:'首页',
    redirect: '/index',
    children:[
      {
        path: '/index',
        name: 'Index',
        menuName:'首页',
        component: () => import('../views/index/index.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
