<template>
    <div v-if="loadingState" id="loading_box" class="loading">
      <img src="./assets/images/2.gif" class="loading_img" />
    </div>
    <router-view />
</template>

<script>
export default {
  data() {
    return {
      loadingState:true
    }
  },
  created() {
    
  },
  mounted() {
    let that = this
    setTimeout(function(){
      that.loadingState = false
    },3500)
  },
}
</script>

<style>
html,body{
  margin:0;
  padding:0;
}
body{
  overflow: hidden;
}

.loading{
  background:url(./assets/images/bg.png);background-size:100% 100%;position: fixed;top:0;left:0;width:100%;height:100%;z-index:1000;
}
.loading_img{width:150px;position: absolute;top:50%;left:50%;margin-top:-75px;margin-left:-75px;}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

#loading_box{
  -webkit-animation-name: fadeIn; /*动画名称*/
  -webkit-animation-duration: 3s; /*动画持续时间*/
  -webkit-animation-iteration-count: 1; /*动画次数*/
  -webkit-animation-delay: 2s; /*延迟时间*/
}

</style>
