import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)
app.use(store)
app.use(router)
app.mount('#app')

/**
 * 金额保留两位并格式化
 * @param {*} num 
 * @returns 
 */
app.config.globalProperties.toMoneyFormat = function(num){
    if(num) {
        if(isNaN(num)){ 
            return num
        }
        num = typeof num === 'string' ? parseFloat(num) : num  // 判断是否是字符串如果是字符串转成数字
        num = num.toFixed(2)  // 保留两位
        num = num.toLocaleString() // 转成金额显示模式
        // 解决数字小于1时，IE只显示小数点后部分的问题
        if (num.substring(0, 1) === '') {
            num = '0' + num
        }
        //判断是否有小数
        if(num.indexOf('.') === -1){
            num = num + '.00'
        }else {
            num = num.split('.')[1].length < 2 ? num + '0' : num
        }
        return num // 返回的是字符串23,245.12保留2位小数
    } else if (num === 0) {
         num = num + '.00'
         return num
    }else {
        return (num = null)
    }
}
